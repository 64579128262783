import team1 from "../assets/webteam/sid.png";

export const navigation = [
  {
    id: 0,
    name: "Home",
    link: "/",
  },
  {
    id: 1,
    name: "Events",
    link: "/events",
  },
  {
    id: 2,
    name: "Workshops",
    link: "/workshops",
  },
  {
    id: 3,
    name: "About",
    link: "/about",
  },
  {
    id: 4,
    name: "Core Team",
    link: "/coreteam",
  },
  {
    id: 5,
    name: "Web Team",
    link: "/webteam",
  },
  {
    id: 6,
    name: "Referrals",
    link: "/referrals",
  },
  {
    id: 7,
    name: "Updates",
    link: "/eventupdates",
  },
  {
    id:8,
    name:"Sponsors",
    link:"/sponsors"
  },
  {
    id: 9,
    name: "Contact",
    link: "/contact",
  },
];

export const CoreTeamData = [
  {
    id: 1,
    name: "Siddhu From Srikakulam",
    role: "UI/UX Designer",
    img: team1,
  },
  {
    id: 2,
    name: "Siddhu From Srikakulam",
    role: "Web Developer",
    img: team1,
  },
  {
    id: 3,
    name: "Siddhu From Srikakulam",
    role: "Web Developer",
    img: team1,
  },
  {
    id: 4,
    name: "Siddhu From Srikakulam",
    role: "Web Developer",
    img: team1,
  },
  {
    id: 5,
    name: "Siddhu From Srikakulam",
    role: "UI/UX Designer",
    img: team1,
  },
  {
    id: 6,
    name: "Siddhu From Srikakulam",
    role: "Web Developer",
    img: team1,
  },
  {
    id: 7,
    name: "Siddhu From Srikakulam",
    role: "Web Developer",
    img: team1,
  },
  {
    id: 8,
    name: "Siddhu From Srikakulam",
    role: "Web Developer",
    img: team1,
  },
];

export const CoreTeam = [
  {
    "name": "T. Pranay Kumar",
    "role": "Overall Coordinator",
    "id": "N190854"
  },
  {
    "name": "K. Mohana Rao",
    "role": "Student Treasurer",
    "id": "N191147"
  },
  {
    "name": "P. Sai Bhavani",
    "role": "Events Manager",
    "id": "N190660"
  },
  {
    "name": "S. Jashwanth",
    "role": "Workshops Manager",
    "id": "N190290"
  },
  {
    "name": "K. Chandu",
    "role": "Design Manager",
    "id": "N190463"
  },
  {
    "name": "K. Satish",
    "role": "Stalls Manager",
    "id": "N190254"
  },
  {
    "name": "M.Sravani",
    "role": "Promotions Manager",
    "id": "N191117"
  },
  {
    "name": "A.Vasanthi",
    "role": "Hospitality Manager",
    "id": "N190453"
  },
  {
    "name": "Y. Pujitha",
    "role": "Marketing Manager",
    "id": "N190299"
  },
  {
    "name": "K. V. Abhinash Kumar",
    "role": "Marketing Coordinator",
    "id": "N200202"
  },
  {
    "name": "K. Krishna Kireeti",
    "role": "Promotions Manager (Out-Campus)",
    "id": "N200026"
  },
  {
    "name": "V.Yaswanth",
    "role": "Web Team Coordinator",
    "id": "N200081"
  },
  {
    "name": "M. Shakunthala",
    "role": "Social Media Manager",
    "id": "N190684"
  },
  {
    "name": "B. Komali Sravya",
    "role": "Disciplinary Manager",
    "id": "N190163"
  },
  {
    "name": "V. L. S. Prabhas",
    "role": "Stage Manager",
    "id": "N191018"
  },
  {
    "name": "D. Anand",
    "role": "Stage Coordinator",
    "id": "N190237"
  },
  {
    "name": "Ch. Abhi Ram",
    "role": "Technical Assistance",
    "id": "N191080"
  },
  {
    "name": "V. Mukesh",
    "role": "Content Writing Manager",
    "id": "N200600"
  },
  {
    "name": "M. Rajeswari",
    "role": "Infra Manager",
    "id": "N190040"
  },
  {
    "name": "G. Mohith Kumar",
    "role": "Project Expo Manager",
    "id": "N191089"
  },
  {
    "name": "A. Dushwanth Reddy",
    "role": "Project Expo Manager",
    "id": "N190707"
  },
  {
    "name": "M. Sravanthi",
    "role": "Guest Talks Manager",
    "id": "N190413"
  },
  {
    "name": "N. Satish",
    "role": "Printing Manager",
    "id": "N190354"
  },
  {
    "name": "B. Hareesh",
    "role": "Hospitality Coordinator",
    "id": "N190066"
  },
  {
    "name": "V. Suresh",
    "role": "Hospitality Coordinator",
    "id": "N180555"
  },
  {
    "name": "P. Ramu",
    "role": "Photography Manager",
    "id": "N190823"
  },
  {
    "name": "S. Bhaskar",
    "role": "Source Person",
    "id": "N190923"
  },
  {
    "name": "C. Lakshmi Naresh",
    "role": "Open to all Events Manager",
    "id": "N190667"
  },
  {
    "name": "P. Balaji",
    "role": "Open to all Events Coordinator",
    "id": "N190868"
  },
  {
    "name": "G. Suryaveni",
    "role": "Cultural Coordinator",
    "id": "N190446"
  },
  {
    "name": "G. Pujitha",
    "role": "Cultural Coordinator",
    "id": "N190133"
  },
  {
    "name": "U. Vijaya Krishna",
    "role": "Cultural Coordinator",
    "id": "N200305"
  }
]


export const verifiedColleges = [
  "Institute of Hotel Management, Rajbagh, Srinagar",
  "National Institute of Fashion Technology, Ompora, Budgam",
  "Govt. Degree College Magam, Beeru",
  "Govt. Degree College, Budgam",
  "Govt. Degree College for Women, Baramulla",
  "Govt. Degree College for Women, Anantanag",
  "Islamia College of Science and Commerce Hawal",
  "Govt. Degree College, Ganderbal",
  "Dr HSJ Institute of Dental Sciences- PU Chandigarh (IDS)",
  "CSIR-Central Scientific Instruments Organisation, Chandigarh (CSIO)",
  "Punjab Engineering College (Deemed to be University), Chandigarh (PEC)",
  "College of Technology, Gbpuat, Pantnagar",
  "National Institute of Technology (NIT), Uttarakhand",
  "Indian Institute Management (IIM), Kashipur",
  "Punjab Engineering College (Deemed To Be University), Chandigarh (PEC)",
  "Jamia Millia Islamia, New Delhi",
  "Islamic University of Science and Technology J&K",
  "IIT Mandi",
  "Indraprastha Institute of Information Technology Delhi",
  "Indira Gandhi Delhi Technical University for Women",
  "Ambedkar University, Delhi",
  "School of Planning and Architecture, Vijayawada",
  "School of Planning and Architecture, Bhopal",
  "Malviya National Institute of Technology, Jaipur",
  "Lachoo Memorial College of Science & Technology, Jodhpur",
  "Birla Institute of Scientific Research, Jaipur",
  "Sanjay Gandhi PG Institute of Medical Sciences (SGPGI), Lucknow",
  "Indian Agricultural Research Institute (IARI), New Delhi",
  "Harcourt Butler Technological University (HBTU), Kanpur",
  "University of Allahabad",
  "Indian Institute of Information Technology, Allahabad",
  "Motilal Nehru National Institute of Technology, Allahabad",
  "Indian Institute of Information Technology, Manipur",
  "National Institute of Technology Meghalaya",
  "Assam Agricultural University Jorhat, Assam",
  "St. Mary’s College (SMC), Shillong",
  "St. Anthony’s College (SAC), Shillong",
  "Indian Institute of Information Technology, Guwahati (IIITG)",
  "St. Aloysius’ College (Autonomous) and St. Aloysius Institute of Technology, Jabalpur",
  "Biotechnology Centre Jawaharlal Nehru Krishi Vishwavidyalaya (JNKVV), Jabalpur",
  "India Institute of Information Technology Design & Manufacturing (IIITDM), Jabalpur",
  "Government Engineering College, Modasa",
  "Dr. S. & S. S. Ghandhy Government Engineering College – Surat",
  "A.V. Parekh Technical Institute – Rajkot",
  "IIEST Shibpur",
  "NIT Jamshedpur",
  "NIT Patna",
  "College of Engineering & Technology (CET), Bhubaneswar",
  "Kendriya Vidyalaya School (KV) – No.1 Bhubaneswar",
  "Bhubanananda Orissa School of Engineering (BOSE), Cuttack",
  "Ravenshaw University (Psychology & Philosophy Department), Cuttack",
  "The National Innovation Foundation (NIF) – India Ahmedabad Gujarat",
  "Dr. Balasaheb Sawantkonkan Krishi Vidyapeeth Dapoli, Maharashtra",
  "College of Engineering Pune",
  "Padmashri Vikhe Patil College of Arts Science & Commerce Pravaranagar Loni, Ahmednagar",
  "Maratha Vidya Prasarak Samaj College of Architecture and Centre for Design, Gangapur Road, Nashik",
  "Indian Institute of Education, Vigyan Ashram, Pabal",
  "IIIT Hyderabad",
  "IIITDM Kancheepuram",
  "IIIT Sricity",
  "IIIT, Rk Valley",
  "Acharya Nagarjuna University",
  "IIIT, Nuzivid",
  "University Visvesvaraya College of Engineering, Bangalore",
  "National Institute of Technology Calicut, Kerala",
  "National Institute of Technology, Surathkal, Karnataka"
];
